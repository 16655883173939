import { useEffect } from "react";

import Image from "next/image";
import { useRouter } from "next/navigation";

import useTranslation from "next-translate/useTranslation";

import frame from "shared/assets/images/frame_phone.png";
import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";
import useMainPage from "shared/utils/hooks/useMainPage";

import { captureException, captureMessage } from "@sentry/nextjs";

import { ColContent, Custom404Container, PrimaryButton, Title } from "./styles";

const alreadyReloadedPageKey = "alreadyReloadedPage";
type IAlreadyReloadedPage = "true" | "false";

const getAlreadyReloadPageFromSessionStorage = (): IAlreadyReloadedPage =>
  (sessionStorage.getItem(alreadyReloadedPageKey) ||
    "false") as IAlreadyReloadedPage;

const saveAlreadyReloadPageInSessionStorage = (
  alreadyReloaded: IAlreadyReloadedPage,
): void => sessionStorage.setItem(alreadyReloadedPageKey, alreadyReloaded);

const Custom404 = () => {
  const router = useRouter();
  const mainPage = useMainPage();
  const { t } = useTranslation();
  const { state } = useGlobalContext();

  const siteName = state.tenancy?.settings.site.siteName;

  useEffect(() => {
    if (typeof window === "undefined") return;

    try {
      const alreadyReloadedPage = getAlreadyReloadPageFromSessionStorage();
      if (alreadyReloadedPage === "true") return;

      saveAlreadyReloadPageInSessionStorage("true");
      window?.location?.reload();
    } catch (error) {
      console.log("Erro (Custom404 | useEffect): ", error);
      captureException(error);
      captureMessage("Erro (Custom404 | useEffect)");
    }
  }, []);

  return (
    <Custom404Container>
      <Title span={24}>{t("404:error404")}</Title>

      <ColContent span={24} button="">
        <h1>{t("404:pageNotExistsTitle")}</h1>
      </ColContent>

      <ColContent span={24} button="">
        {t("404:pageNotExists")}
      </ColContent>

      <ColContent span={24} button="true">
        <PrimaryButton
          type="button"
          onClick={() => router.push(`/${mainPage}`)}
        >
          {siteName}
        </PrimaryButton>
      </ColContent>

      <ColContent span={24} button="">
        <div
          style={{ position: "relative", width: "600px", padding: "8px 16px" }}
        >
          <Image src={frame} alt="..." objectFit="contain" />
        </div>
      </ColContent>
    </Custom404Container>
  );
};

export default Custom404;
